import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import HomeLayout from '../components/layouts/HomeLayout'
import Seo from '../components/seo'
import CardList from '../components/common/CardList/CardList'
import useLink from '../hooks/useLink'
import EventCard from '../components/common/EventCard/EventCard'
import PagesListBanner from '../components/common/PagesListBanner/PagesListBanner'

const EventListPageTemplate = props => {
  const intl = useIntl()
  const { buildURL } = useLink()
  const location = typeof window !== 'undefined' ? window.location : { pathname: '' }
  const pages = get(props, 'data.allContentfulPageEvenement.edges', []).map(edge => edge.node)

  const searchParams = new URLSearchParams(location.search)

  return (
    <HomeLayout location={location}>
      <Seo title={intl.formatMessage({ id: 'pages-list.Event.title' })} />
      <PagesListBanner
        title={intl.formatMessage({ id: 'pages-list.Event.title' })}
        subtitle={intl.formatMessage({ id: 'pages-list.Event.subtitle' })}
      />
      <div className="container">
        <CardList
          items={pages.map(page => (
            <EventCard to={buildURL(page)} {...page} key={page.id} />
          ))}
          perPage={12}
          initialPage={searchParams.has('page') ? Number(searchParams.get('page')) : 1}
        />
      </div>
    </HomeLayout>
  )
}

export default EventListPageTemplate

export const pageQuery = graphql`
  query EventListQuery($locale: String!) {
    allContentfulPageEvenement(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          id
          slug
          type
          node_locale
          startDate
          endDate
          title
          image {
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`
